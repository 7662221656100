var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.filteredMsg.length)?_c('div',{staticClass:"global-message",class:_vm.hideOverlay == true ? 'no-overlay' : ''},[_c('div',{staticClass:"message-container"},[(_vm.filteredMsg.length)?[_vm._l((_vm.filteredMsg),function(message,index){return [_c('transition',{key:index,attrs:{"name":"custom-classes-transition","enter-active-class":"animated fadeInUp","leave-active-class":"animated fadeOutUp"}},[(message.shown == true)?_c('div',{key:index,staticClass:"alert",class:[message.type == 'success'
                ? 'alert-success'
                : message.type == 'error'
                ? 'alert-danger'
                : 'aler-danger-top',
            ]},[_c('span',{staticClass:"close-icon",on:{"click":function($event){return _vm.hideMessage(index)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])]),_c('span',{domProps:{"innerHTML":_vm._s(message.message)}}),(message.type != 'success' && message.type != 'errorOnTop')?[_c('div',{staticClass:"btn-wrapper"},[(!_vm.checkReloadMessage)?[_c('a',{class:[
                      message.type == 'success' ? 'btn btn-success' : 'btn btn-danger',
                    ],attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.hideMessage(index)}}},[_vm._t("ok",function(){return [_vm._v(" "+_vm._s(_vm.btnText)+" ")]})],2)]:[_c('a',{class:[
                      message.type == 'success' ? 'btn btn-success' : 'btn btn-danger',
                    ],attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.reloadPage()}}},[_vm._t("ok",function(){return [_vm._v(" "+_vm._s(_vm.btnText)+" ")]})],2)]],2)]:_vm._e()],2):_vm._e()])]})]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }