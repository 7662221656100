var docvaultServiceUrl = window.app.vars.docvault_service
var docvaultAuthId = window.app.vars.docvault_auth_id
// Removed /api from docvault service url to pass to bottom
var docvaultBaseUrl = docvaultServiceUrl.replace('/api','');
var taskManagerServiceUrl = window.app.vars.task_manager_service;
var notificationHubServiceUrl = taskManagerServiceUrl.replace('/taskmanagerservice/api','')
notificationHubServiceUrl = notificationHubServiceUrl.replace('/api','')
window.config = {
	VUE_APP_STORAGE_PREFIX: "ch_",
	VUE_APP_BASE_URL: window.app.vars.base_url,
	VUE_APP_DOCVAULT_BASE_URL: docvaultBaseUrl,
	VUE_APP_DOCVAULT_AUTHID: docvaultAuthId,
	VUE_APP_SETTINGS: {
			"services":{
				"loan_service" :{
					"base_url": window.app.vars.loan_service,
					"token": "",
					"endpoints": {
						"deals": "/Deals",
						'getDealIdFromNameSearch': {
							'post': '/Deals/GetIdsFromNameSearch'
						},
						"getLoanTypes": "/Deals/GetLoanTypes",
						"setDealName": "/Deals/SetDealName",
						"setLoanTypes": "/Deals/SetLoanType",
						"setVender": "/Deals/SetVender",
						"facilitiesByDealId": {
							"get": "/Facility/GetFacilitiesByDealId"
						},
						"allFacilities": {
							"post":"/Facility"
						},
            "allMandates": {
              "post":"/Vendor"
            },
						"setActivation": {
							"put": "/Deals/SetActivation"
						}
					}
				},
				"vendor_service":{
					// "base_url": window.app.vars.base_url,
					"base_url": window.app.vars.loan_service,
					"token":"",
					"endpoints":{
						"vendors": "/Vendor"
					}
				},
				"task_service":{
					"base_url": window.app.vars.task_manager_service,
					"token":"",
					"endpoints":{
						"bulkActions": {
							"post": "data/task/bulkActions.json"
						},
						"taskList": "data/task/taskList.json",
						"userTask": {
							"post": "/Task/Get",
							"put": "/Task",
							"delete": "/Task"
						},
						"newTask": {
							"post": "/Task",
						},
            "newServiceRequest": {
              "post": "/ServiceRequest/InsertServiceRequest"
            },
						"taskTags": {
							"get": "/Task/GetTags",
							"post": "/Task/UpdateTags"
						},
						"viewerTask": "data/task/taskViewer.json",
						"primaryRoles": "data/task/primaryRoles.json",
						"secondaryRoles": "data/task/secondaryRoles.json",
						"approverRoles": "data/task/approverRoles.json",
						"rollConventions": "data/task/rollConventions.json",
						"taskWorkbench": "data/task/taskWorkBench.json",
						"templateManager": {
							"get" : "/TaskTemplates/DefaultTasks",
              "getSlas": "/TaskTemplates/Slas"
						},
						"rolesManager": "/5e70853830000029007a2f5f",
						"generatedTasks" : "data/task/generated.json",
						"taskTemplates":{
							"get": "/TaskTemplates",
							"post": "/TaskTemplates",
							"put": "/TaskTemplates",
							"delete": "/TaskTemplates"
						},
						"taskComments": {
							"get": "/TaskComments",
							"post": "/TaskComments",
							"put": "/TaskComments",
							"delete": "/TaskComments"
						},
						"templateDefaultTasks" : {
							"get" : "/TaskTemplates/DefaultTasks",
							"post": "/TaskTemplates/DefaultTasks",
							"put": "/TaskTemplates/DefaultTasks",
							"delete": "/TaskTemplates/DefaultTasks"
						},
            "templateSlas" : {
              "get": "/TaskTemplates/Slas",
              "post": "/TaskTemplates/Slas",
              "put": "/TaskTemplates/Slas",
              "delete": "/TaskTemplates/Slas"
            },
            "templateGroups": {
              "get": "/TaskTemplates/TemplateGroups"
            },
						"taskRolesManager": {
							"get" : "data/task/taskRoles.json"
						},
						"existingScheduled" : "data/task/existing_scheduled.json",
						"refObjectRoles": {
							"get" : "/ReferenceObjectRoles",
							"post": "/ReferenceObjectRoles",
							"put": "/ReferenceObjectRoles",
							"delete": "/ReferenceObjectRoles"
						},
						"taskConfigurations" : {
							"get": "/TaskConfiguration",
							"post": "/TaskConfiguration",
							"put": "/TaskConfiguration",
							"delete": "/TaskConfiguration"
						},
						"taskRoleConfigurations": "/TaskConfigurationTaskRoleConfiguration",
						"taskHistory" : "data/task/taskHistory.json",
						"assignmentType" : "/TaskRoleTypes",
						"taskGenerator" : {
							"post": "/GeneratedTasks/GetTasks"
						},
						"saveTask" : {
							"post": "/GeneratedTasks/PostTasks",
              "put": "/Task"
						},
						"statuses": {
							"get": "data/task/taskStatuses.json"
						},
						"taskById": {
							"get": "/Task/GetById"
						},
						"actionsByTaskId": {
							"get": "/StatusTransitions/GetActionsByTaskId"
						},
						"getAllByTaskGroup": {
							"get": "/StatusTransitions/GetAllByTaskGroup"
						},
						"referenceAssignment": {
							"get": "data/task/referenceAssignment.json"
						},
						"logTypes" : "data/task/logTypes.json",
						"taskCount" : {
							"post": "/TaskCount/Get"
						},
						"taskExtract": {
							"get" : "/TaskExtract/GetForExcel"
						},
						"taskStaticData": {
							"get": "/StaticData"
						},
						"sla": {
							"get" : "/SLA/GetForExcel"
						},
            "overdue":{
              "get": "/TaskExtract/DiaryReport"
            },
            "upcoming": {
              "get": "/TaskExtract/UpcomingTasksReport"
            },
						"slaWeb": {
							"post" : "/SLA/GetForWeb"
						},
						"switchUsers": {
							"post" : "/Task/SwitchUsers"
						},
						"taskHistory": {
							"get" : "/TaskHistory"
						},
						"workflow": {
							"get": "/Workflow/get",
							"post": "/Workflow",
							"put": "/Workflow",
							"delete": "/Workflow"
						},
						"workFlowLists": {
							"get": "/Workflow"
						},
						"workflowById": {
							"get": "/Workflow/GetById"
						},
						"workflowComments": {
							"get": "/WorkflowComments",
							"post": "/WorkflowComments",
							"put": "/WorkflowComments",
							"delete": "/WorkflowComments"
						},
						"docvaultAuthentication": {
							"get": "/ExternalAuthentication/DocVault"
						},
						"setActivation": {
							"put": "/Task/SetActivation"
						},
						'notifications': {
							'get': '/Notifications',
							'put': '/Notifications/Clear'
						},
						'dispatchNotification': {
							'get': '/Notifications/Dispatch'
						},
            'assignTasksToMe': {
              'post': '/Task/AssignToMe'
            },
            'assignWorkflowToMe': {
              'post': '/Workflow/AssignToMe'
            }
					}
				},
				"reference_service":{
					"base_url": window.app.vars.profile_service,
					"token":"",
					"endpoints":{
						"referenceTypes": {
							"get" : "/ReferenceTypes"
						},
						"roleSupervisor" : {
							"get" : "/Roles",
							"post" : "/Roles",
							"put" : "/Roles",
							"delete" : "/Roles"
						},
						"undeleteRoles": {
							"put": "/Roles/UnDelete",
						}
					}
				},
				"user_services": {
					"base_url": window.app.vars.profile_service,
					"token": "",
					"endpoints": {
						"allUsers": {
							"get":"/User/getall",
							"post": "/User/PostUser",
							"put": "/User/PutUser",
							"delete": "/User/DeleteUser"
						},
            "allTeams": {
              "get": "/group/teams"
            },
            "allGroups": {
              "get":"/group"
            },
            "allGroupTypes": {
              "get": "/group/groupType"
            },
            "approverGroups": {
              "get": "/group/approverGroups"
            },
						"userUnDelete": {
							"put": "/User/UnDeleteUser"
						},
						"userLogin": {
							"post":"/User/authenticate"
						},
						"getuser": "/User/get",
						"userGroup": {
							"get": "/Group",
							"post": "/Group",
							"put": "/Group",
							"delete": "/Group"
						},
						"groupUnDelete": {
							"put": "/Group/UnDelete"
						},
						"manageGroup": {
							"get": "/Group/getByUserId"
						},
						"manageGroupGetByGroupId": {
							"get": "/Group/getByGroupId"
						},
						"manageUserToGroup": {
							"post": "/UserGroup",
							"put": "/UserGroup",
							"delete": "/UserGroup",
							"get": "/UserGroup/GetUserGroupsByGroupId"
						},
						"roleByUserId": {
							"get": "/UserRole/getByUserId"
						},
						"userRole": {
							"post": "/UserRole"
						},
						"userRoles": "data/user/userRoles.json",
						"userPermission": "/Permission",
						"userSystemPermission": "data/user/userSystemPermission.json",
						"userMandate": "/MandateGroup",
						"mandateGroupMandate": "/MandateGroup/mandate",
						"userResources": {
							"get": "/Resource",
							"delete": "/Resource",
							"put": "/Resource/UnDelete"
						},
						"managemandategroup": {
							"get": "/MandateGroup",
							"post": "/MandateGroup"
						},
						"updateMandate": {
							"delete": "/Mandate",
							"put": "/Mandate/UnDelete"
						},
						"userSystemPermissionGroup": "data/user/userSystemPermissionGroup.json",
						"userPreferences": {
							"get": "/UserPreferences",
							"post": "/UserPreferences",
							"put": "/UserPreferences",
							"delete": "/UserPreferences"
						},
						"getRoles": {
							"get": "/Roles",
							"post": "/Roles",
							"put": "/Roles",
							"delete": "/Roles"
						},
						"userValidate": {
							"post":"/User/validate"
						},
						"userPassword": {
							"put": "/User/updatePassword"
						},
						"forcePassword": {
							"put": "/User/forcePasswordReset"
						},
						"userAcl": {
							"get": "/User/getUserACL"
						},
            "userAclEmail": {
              "get": "/User/getUserACL"
            },
						"groupResource": {
							"post": '/GroupResource',
							"put": '/GroupResource'
						},
						"groupUnDelete": {
							"put": "/Group/UnDelete"
						},
						"copyUserGroups": {
							"post": "/UserGroup/CopyGroupsFromOneUserToAnother"
						}
					}
				},
				"role_service":{
					"base_url": window.app.vars.profile_service,
					"token":"",
					"endpoints":{
						"roles": "/Roles",
						"primaryRoles": "/Roles",
						"secondaryRoles": "/Roles",
						"approverRoles": "/Roles"
					}
				},
				"static_data_service":{
					"base_url":  window.app.vars.static_data_service,
					"token":"",
					"endpoints":{
						"business_calendars": {
							"get": "/BusinessCalendar",
							"post": "/BusinessCalendar"
						},
						"dateOffset": {
							"post": "/DateOffset/GetDateOffSet"
						},
						"holiday_calendars": {
							"get": "/Holiday",
							"post": "/Holiday"
						},
						"staticData": {
							"get": "/StaticData"
						},
						"business_calendar_template": {
							"get": "/BusinessCalendarTemplate"
						},
						"holiday_template": {
							"get": "/HolidayTemplate"
						},
						"duedate_calculator": {
							"get": "/DueDateCalculation",
							"post": "/DueDateCalculation/GetRemainingTime"
						}
					}
				},
				"organization_service": {
					"base_url": window.app.vars.profile_service,
					"token": "",
					"endpoints": {
						"organizations": {
							"get": "/Organisation"
						}
					}
				},
				"docvault_service": {
					"base_url": window.app.vars.docvault_service,
					"token": "",
					"endpoints": {
						"users": {
							"post": "/users/task-manager"
						},
						"user_authenticate": {
							"post": "/oauth/authenticate"
						},
						"projects": {
							"post": "/api/projects",
							"get": "/api/projects"
						},
						"projectsListing": {
							"get": "/api/projects/all-listing"
						},
						"searchProject": {
							"post": "/api/projects/search"
						},
						"search": {
							"post": "/search"
						},
						"mediaById": {
							"get": "/media",
							"delete": "/media"
						},
						"mediaTags": {
							"patch": "/media"
						},
						"mediaCount": {
							"post": "/workflows"
						},
						'attachmentCount': {
							'post': '/v2/workflows'
						}
					}
				},
        "identityServer": {
          "baseUrl": window.app.vars.identityServer,
          endpoints: {
            "user_authenticate": {
            "post": "/connect/authorize"
            },
          },

        }

			}
	},
	docvault:{
		// keycloak_url:"https://docvault.crecentric.com/auth/",
		// keycloak_id:"crecentric-dealvault",
		// keycloak_realm:"docvault",
		url: docvaultBaseUrl,
		max_file_chunk:"104857600",
		max_chunk_size: "1073741824",
		max_file_upload:"10",
		// email:"taskmanagerupload@mountstreetllp.com",
		// client_id:"taskmanager.dev",
		// client_secret:"8181a2eb-c8b0-434f-8fe6-9c960c521b5f",
		realms:"docvault"
	},
	notification: {
		hub_url: notificationHubServiceUrl+'/notificationhub'
	}
};
