import BaseManager from '@/library/system/manager'
import Model from './model'
import BaseService from '../system/service'
import { isNumber } from 'lodash'

class UserManager extends BaseManager {
    constructor () {
        super()
        this.model = new Model()
        this.user_service = new BaseService('user_services')
    }

    // getUsers (callback) {
    //     this.user_service.fetch('allUsers',callback, {messages:{error:''}})
    // }

    getUsers(callback) {
        let params = `?includeInactive=true`
        axios({
            url: this.user_service.getServiceUrl("allUsers", "get")+params,
            method: 'get',
        }).then(response => {
            // window.messages.push({type: 'success', shown:false, message: ''})
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error)
        })
    }

    getTeams(callback) {
      let params=`?includeInactive=false`;
      axios({
        url: this.user_service.getServiceUrl("allTeams", "get") + params,
        method: 'get',
      }).then(response => {
        window.messages.push({type: 'success', shown:false, message: ''})
        callback(response.data)
      }).catch(error => {
        window.messages.push({ type: 'error', shown: false, message: error.response.data.responseException.exceptionMessage })
        callback(error)
      });
    }
  getApproverGroups(callback) {
    let params = `?includeInactive=false`;
    axios({
      url: this.user_service.getServiceUrl("approverGroups", "get") + params,
      method: 'get',
    }).then(response => {
      window.messages.push({ type: 'success', shown: false, message: '' })
      callback(response.data)
    }).catch(error => {
      window.messages.push({ type: 'error', shown: false, message: error.response.data.responseException.exceptionMessage })
      callback(error)
    });
  }
    getUserRoles (callback) {
        this.user_service.fetch('userRoles',callback, {messages:{error:''}})
    }

    getUserProfile (userId, callback) {
        this.user_service.fetch('getuser',callback, {'data':{'userId': userId}, messages:{error:''}})
    }

    userValidatePasswrod (userId, password, callback) {
        this.user_service.fetch('userValidate', callback, {
            'method': 'post',
            'data': {
                'id': userId,
                "password": password
            },
            messages:{error:'Current Password is incorrect'}
        })
    }

    userupdatePasswrod (data, callback) {
        this.user_service.fetch('userPassword', callback, {
            'method': 'put',
            'data': data,
            messages:{success: 'Password changed successfully', error:''}
        })
    }

    userForceResetPassword (data, callback) {
        this.user_service.fetch('userPassword', callback, {
            'method': 'put',
            'data': data,
            messages:{error:''}
        })
    }

    // forceResetPassword (userId, callback) {
    //     this.user_service.fetch('forcePassword', callback, {
    //         'method': 'put',
    //         'data': {
    //             'id': userId,
    //         },
    //         messages:{success: 'Force password reset done successfully', error:''}
    //     })
    // }

    forceResetPassword (data, callback) {
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Force password resets done successfully'
        else successMsg = 'Force password reset done successfully'
        this.user_service.fetch('forcePassword', callback, {
            'method': 'put',
            'data': data,
            messages:{success: successMsg, error:''}
        })
    }


    changeAndForceResetPassword (userId, callback) {
        let data = [{'id': userId}]
        this.user_service.fetch('forcePassword', callback, {
            'method': 'put',
            'data': data,
            messages:{error:''}
        })
    }

    authenticate (data, callback) {
        this.user_service.fetch('userLogin',(response, error) => {
            if(response){
                const token = response.result.token
                const userProfile = {
                    // agreeToTerms: response.result.agreeToTerms,
                    email: response.result.email,
                    firstName: response.result.firstName,
                    lastName: response.result.lastName,
                    id: response.result.id,
                    organisationId: response.result.organisationId,
                    organisation: response.result.organisation,
                    forcePasswordReset: response.result.forcePasswordReset,
                    loginStatus: true
                }
                window.setStorage('user-token', token)
                window.setStorage('user-profile', JSON.stringify(userProfile))
            }
            callback(response, error)
        }, {'method': 'post', 'data': data, messages:{}})
    }

    getGroupByUserId(id, callback) {
        this.user_service.fetch("manageGroup", callback, {
            method: "get",
            data: {userId: id},
            messages: {error:''}
        })
    }

    getRolesByUserId(id, callback) {
        this.user_service.fetch("roleByUserId", callback, {
            method: "get",
            data: {userId: id, isActive: true},
            messages: {error:''}
        })
    }

    getGroupByGroupId(id, callback) {
        this.user_service.fetch("manageGroupGetByGroupId", callback, {
            method: "get",
            data: {groupId: id},
            messages: {error:''}
        })
    }

    getUserGroups(callback){
        this.user_service.fetch('userGroup',callback, {messages:{error:''}})
    }

    getAllGroups(callback) {
      this.user_service.fetch('allGroups', callback, { messages: { error: '' } })
    }

    getAllGroupTypes(callback) {
      this.user_service.fetch('allGroupTypes', callback, { messages: { error: '' } })
    }

    getUserPermission(callback){
        this.user_service.fetch('userPermission',callback, {messages:{error:''}})
    }

    getUserSystemPermission(callback){
        this.user_service.fetch('userSystemPermission',callback, {messages:{error:''}})
    }

    getUserMandate(callback){
        this.user_service.fetch('userMandate',callback, {messages:{error:''}})
    }

    deleteMandate(data, callback){
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Mandates Status Set to Inactive'
        else successMsg = 'Mandate Status Set to Inactive'
        this.user_service.fetch('updateMandate',callback, {
            'method': 'delete',
            'data': data,
            messages:{success: successMsg, error:''}
        })
    }

    undeleteMandate(data, callback){
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Mandates Status Set to Active'
        else successMsg = 'Mandate Status Set to Active'
        this.user_service.fetch('updateMandate',callback, {
            'method': 'put',
            'data': data,
            messages:{success: successMsg, error:''}
        })
    }

    getmanagemandategroup(callback){
        this.user_service.fetch('managemandategroup',callback, {messages:{error:''}})
    }

    postMandateGroup(data, callback){
        this.user_service.fetch('managemandategroup',callback, {'method': 'post', 'data': data, messages:{error:''}})
    }

    putMandateGroup(data, callback){
        this.user_service.fetch('managemandategroup',callback, {'method': 'put', 'data': data, messages:{error:''}})
    }

    getMandateGroupMandate(data, callback) {
        let params = `?mandateName=${data}`
        axios({
            url: this.user_service.getServiceUrl("mandateGroupMandate", "get") + params,
            method: 'get',
        }).then(response => {
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error.response.data)
        })
    }

    getUserResources(callback) {
        this.user_service.fetch("userResources", callback, { messages: {error: ''} })
    }

    deleteResource(data, callback){
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Resources Status Set to Inactive'
        else successMsg = 'Resource Status Set to Inactive'
        this.user_service.fetch('userResources',callback, {
            'method': 'delete',
            'data': data,
            messages:{success: successMsg, error:''}
        })
    }

    undeleteResource(data, callback){
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Resources Status Set to Active'
        else successMsg = 'Resource Status Set to Active'
        this.user_service.fetch('userResources',callback, {
            'method': 'put',
            'data': data,
            messages:{success: successMsg, error:''}
        })
    }

    getUserSystemPermissionGroups(callback) {
       this.user_service.fetch("userSystemPermissionGroup", callback, { messages: {error: ''} })
    }

    postUserGroups(data, callback){
        axios({
            url: this.user_service.getServiceUrl("userGroup", "post"),
            method: 'post',
            data: data,
        }).then(response => {
            window.messages.push({type: 'success', shown:false, message: 'Group Added Successfully'})
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error.response.data)
        })
    }

    putUserGroups(data, callback){
        axios({
            url: this.user_service.getServiceUrl("userGroup", "put"),
            method: 'put',
            data: data,
        }).then(response => {
            window.messages.push({type: 'success', shown:false, message: 'Group Updated Successfully'})
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error.response.data)
        })
    }

    deleteUserGroups(data, callback){
        let totalGroupCount = data.length
        axios({
            url: this.user_service.getServiceUrl("userGroup", "delete"),
            method: 'delete',
            data: data,
        }).then(response => {
            if (totalGroupCount > 1) {
                window.messages.push({type: 'success', shown:false, message: 'Groups Status Set to Inactive'})
            } else {
                window.messages.push({type: 'success', shown:false, message: 'Group Status Set to Inactive'})
            }
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error.response.data)
        })
    }

    unDeleteGroups(data, callback){
        let totalGroupCount = data.length
        axios({
            url: this.user_service.getServiceUrl("groupUnDelete", "put"),
            method: 'put',
            data: data,
        }).then(response => {
            if (totalGroupCount > 1) {
                window.messages.push({type: 'success', shown:false, message: 'Groups Status Set to Active'})
            } else {
                window.messages.push({type: 'success', shown:false, message: 'Group Status Set to Active'})
            }
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error.response.data)
        })
    }

    updateUserGroupPermission(data, callback){
        this.user_service.fetch("groupResource", callback, {
            method: "post",
            data: data,
            messages: {success: 'Permission Updated Successfully', error:''}
        })
    }

    putUserGroupPermission(data, callback){
        this.user_service.fetch("groupResource", callback, {
            method: "put",
            data: data,
            messages: {success: 'Permission Updated Successfully', error:''}
        })
    }

    postUsers(data, callback){
        this.user_service.fetch("allUsers", callback, {
            method: "post",
            data: data,
            messages: {success: 'User Added Successfully', error:''}
        })
    }

    putUsers(data, callback){
        this.user_service.fetch("allUsers", callback, {
            method: "put",
            data: data,
            messages: {success: 'User Updated Successfully', error:''}
        })
    }

    // deleteUser(id, callback) {
    //     let params = `?userId=${id}`
    //     axios({
    //         url: this.user_service.getServiceUrl("allUsers", "delete")+params,
    //         method: 'delete',
    //     }).then(response => {
    //         window.messages.push({type: 'success', shown:false, message: 'User Deleted Successfully'})
    //         callback(response.data)
    //     }).catch(error => {
    //         window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
    //         callback(error.response.data)
    //     })
    // }

    deleteUser(data, callback){
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Users Status Set to Inactive'
        else successMsg = 'User Status Set to Inactive'
        this.user_service.fetch("allUsers", callback, {
            method: "delete",
            data: data,
            messages: {success: successMsg, error:''}
        })
    }

    undeleteUser(data, callback){
        let totalDataCount = data.length
        let successMsg = ""
        if (totalDataCount > 1) successMsg = 'Users Status Set to Active'
        else successMsg = 'User Status Set to Active'
        this.user_service.fetch("userUnDelete", callback, {
            method: "put",
            data: data,
            messages: {success: successMsg, error:''}
        })
    }

    getUserByGroupId(id, callback) {
        let params = `?groupId=${id}`
        axios({
            url: this.user_service.getServiceUrl("manageUserToGroup", "get")+params,
            method: 'get',
        }).then(response => {
            // window.messages.push({type: 'success', shown:false, message: ''})
            callback(response.data)
        }).catch(error => {
            window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
            callback(error.response.data)
        })
    }

    putUserToGroup(data, callback){
        this.user_service.fetch("manageUserToGroup", callback, {
            method: "put",
            data: data,
            messages: {error:''}
        })
    }

    postUserToGroup(data, callback){
        this.user_service.fetch("manageUserToGroup", callback, {
            method: "post",
            data: data,
            messages: {error:''}
        })
    }

    deleteUserToGroup(data, showMsg, callback){
        let showAlertMsg = showMsg
        if (showAlertMsg === true) {
            let totalDataCount = data.length
            let successMsg = ''
            if (totalDataCount > 1) successMsg = 'Users removed from group successfully'
            else successMsg = 'User removed from group successfully'

            this.user_service.fetch("manageUserToGroup", callback, {
                method: "delete",
                data: data,
                messages: {success: successMsg, error:''}
            })
        } else if (showAlertMsg === false) {
            this.user_service.fetch("manageUserToGroup", callback, {
                method: "delete",
                data: data,
                messages: {error:''}
            })
        }
    }

    addUserRoles(data, callback) {
        this.user_service.fetch("userRole", callback, {
            method: "post",
            data: data,
            messages: {error:''}
        })
    }

    getUserPermissionAcl(userId, callback){
        this.user_service.fetch("userAcl", (response, error) => {
          var permissionData = [];
            if (response) {
              if(response.result.length == 0){
                // permissionData.push({resource: "Dashboard", permission: "ReadWrite"})
                response.emptyACLData = true;
              } else if(response.result){
                response.result.forEach( (mandates) => {
                  mandates.groups.forEach( (groups) => {
                    if(groups.resources.length > 0) {
                        groups.resources.forEach( (resources) => {
                            if (!permissionData.some( resourceexists => resourceexists.resource === resources.name)){
                                permissionData.push({resource: resources.name, permission: resources.permission.name })
                            }
                        })
                    }else {
                        // permissionData.push({resource: "Dashboard", permission: "ReadOnly"});
                        response.emptyResources = true;
                    }
                  })
                })
              }
              window.setStorage('aclData', JSON.stringify(permissionData))
            }
            callback(response, error)
        }, {'data':{'userId': userId}, messages:{error:''}})
    }

  getUserPermissionAclByEmail(email, callback) {
    this.user_service.fetch("userAcl", (response, error) => {
      var permissionData = [];
      if (response) {
        if (response.result.length == 0) {
          // permissionData.push({resource: "Dashboard", permission: "ReadWrite"})
          response.emptyACLData = true;
        } else if (response.result) {
          response.result.forEach((mandates) => {
            mandates.groups.forEach((groups) => {
              if (groups.resources.length > 0) {
                groups.resources.forEach((resources) => {
                  if (!permissionData.some(resourceexists => resourceexists.resource === resources.name)) {
                    permissionData.push({ resource: resources.name, permission: resources.permission.name })
                  }
                })
              } else {
                // permissionData.push({resource: "Dashboard", permission: "ReadOnly"});
                response.emptyResources = true;
              }
            })
          })
        }
        window.setStorage('aclData', JSON.stringify(permissionData))
      }
      callback(response, error)
    }, { 'data': { 'userId': userId }, messages: { error: '' } })
  }

    copyUserGroups(data, callback) {
        this.user_service.fetch("copyUserGroups", callback, {
            method: "post",
            data: data,
            messages: {success: 'Groups Copied Successfully', error:''}
        })
    }
}

export default new UserManager()